export const tabs = [
  {
    title: 'SOFA',
    items: [
      {
        href: '/wooden-sofa',
        src: 'cache/data/fabric-sofa/Mellisa-fabric-sofa/1-750x650.jpg',
        alt: 'Wooden Sofa',
        name: 'Wooden Sofa',
      },
      {
        href: '/fabric-sofas',
        src: 'cache/data/fabric-sofa/Fabric-Sofa-Combination/0_updated-look/blush/c-750x650.jpg',
        alt: 'Fabric Sofa',
        name: 'Fabric Sofa',
      },
      {
        href: '/l-shaped-sofas',
        src: 'cache/data/sofa-beds/henry-right-aligned-convertible-sofa-cum-bed-with-storage-velvet-salmon-pink/1-750x650.jpg',
        alt: 'L Shaped Sofa',
        name: 'L Shaped Sofa',
      },
      {
        href: '/chaise-lounges',
        src: 'cache/data/chaise-lounges/crocus-chaise-lounge-revised/olive-gold/updated/updated/1-750x650.jpg',
        alt: 'Chaise Lounges',
        name: 'Chaise Lounges',
      },
      {
        href: '/modular-sofas',
        src: 'cache/data/sofa-beds-mdf/ectar-sofa-cum-bed-with-box-storage/flowery-wenge/updated/updated-new/1-750x650.jpg',
        alt: 'Modular Sofas',
        name: 'Modular Sofas',
      },
      {
        href: '/three-seater-sofas',
        src: 'cache/data/fabric-sofa/osbert-3-seater-curved-sofa/jade-ivory/new+/1-750x650.jpg',
        alt: '3 Seater Sofa',
        name: '3 Seater Sofa',
      },
      {
        href: '/two-seater-sofas',
        src: 'cache/data/wooden-sofa/saket-2-seater-teak-wood-cane-sofa-sand-beige/looks/2-seater/4-750x650.jpg',
        alt: '2 Seater Sofa',
        name: '2 Seater Sofa',
      },
      {
        href: '/sofa-cum-beds',
        src: 'cache/data/sofa-beds/feltro-sofa-cum-bed/Printed+Dual+Fabric/cream-tropical/honey-finish/3-750x650.jpg',
        alt: 'Sofa Cum Beds',
        name: 'Sofa Cum Beds',
      },
      {
        href: '/chesterfield-sofas',
        src: 'cache/data/fabric-sofa/swanson-sofa-revised/graphite-grey/updated/3-750x650.jpg',
        alt: 'Chesterfield Sofa',
        name: 'Chesterfield Sofa',
      },
      {
        href: '/office-sofas',
        src: 'cache/data/fabric-sofa/henry-fabric-sofa/olive-gold/updated/1-750x650.jpg',
        alt: 'Office Sofas',
        name: 'Office Sofas',
      },
      {
        href: '/recliners',
        src: 'cache/data/recliner/arvana-suede-fabric-100-polyester-3-seater-manual-middle-seat-fixed-recliner-grey/1-750x650.jpg',
        alt: 'Recliners',
        name: 'Recliners',
      },
      {
        href: '/outdoor-sofas',
        src: 'cache/data/deveko/stylish-grey-rope-outdoor-furniture-set/1-750x650.jpg',
        alt: 'Outdoor Sofas',
        name: 'Outdoor Sofas',
      },
    ],
  },
  {
    title: 'LIVING ROOM',
    items: [
      {
        href: '/coffee-tables',
        src: 'cache/data/coffee-table/vesta-coffee-table-revised/revised/walnut+finish/Cream+Blossom/updated+new/1-750x650.jpg',
        alt: 'Coffee Tables',
        name: 'Coffee Tables',
      },
      {
        href: '/sofa-sets',
        src: 'cache/data/fabric-sofa/Fabric-Sofa-Combination/osbert-3-seater-living-room-sofa-with-2-lounge-chairs-and-4-cushions/indigo-blue-velvet-cream-checkered-fabric/3-750x650.jpg',
        alt: 'Sofa Set',
        name: 'Sofa Set',
      },
      {
        href: '/tv-units',
        src: 'cache/data/tv-units-mdf/daisy-tv-unit/exotic-teak/updated/000000000110000200-750x650.jpg',
        alt: 'TV Units',
        name: 'TV Units',
      },
      {
        href: '/lounge-chairs',
        src: 'cache/data/lounge-chairs/joan-lounge-chair/a-new-front/chestnut-brown/setup/look-2023/1lk-750x650.jpg',
        alt: 'Lounge Chairs',
        name: 'Lounge Chairs',
      },
      {
        href: '/nest-of-tables',
        src: 'cache/data/coffee-table/dojo-coffee-table/updated/updated/1-750x650.jpg',
        alt: 'Nest Of Tables',
        name: 'Nest Of Tables',
      },
      {
        href: '/shoe-racks',
        src: 'cache/data/shoe-rack/hopkin-shoe-rack-revised/honey/updated/1-750x650.jpg',
        alt: 'Shoe Racks',
        name: 'Shoe Racks',
      },
      {
        href: '/bookshelves',
        src: 'cache/data/book-shelves/cagney-bookshelf-revised/honey/updated/1-750x650.jpg',
        alt: 'Bookshelves',
        name: 'Bookshelves',
      },
      {
        href: '/cabinet-sideboards',
        src: 'cache/data/cabinet-sideboards/rylan-cabinet-and-sideboard-walnut-finish/Live-images/product/21-750x650.jpg',
        alt: 'Cabinets & Sideboards',
        name: 'Cabinets &amp; Sideboards',
      },
      {
        href: '/side-tables',
        src: 'cache/data/side-end-table-mdf/facile-square-side-end-table/flowery-wenge/updated/1-750x650.jpg',
        alt: 'Side & End Tables',
        name: 'Side &amp; End Tables',
      },
      {
        href: '/recliners',
        src: 'cache/data/recliner/valencia-leatherette-motorized-recliner-sofa-potters-clay-brown/updated/updated/1-750x650.jpg',
        alt: 'Recliners',
        name: 'Recliners',
      },
      {
        href: '/benches',
        src: 'cache/data/benches/reyes-bench-with-back-rest/updated/rosy-leaf/updated/1-750x650.jpg',
        alt: 'Benches',
        name: 'Benches',
      },
      {
        href: '/l-shaped-sofas',
        src: 'cache/data/corner-sofa/henry-3+3-l-shape-corner-sofa-velvet-chestnut-brown/henry-l-shape-corner-sofa-velvet-graphite-grey/2-750x650.jpg',
        alt: 'L Shaped Sofa',
        name: 'L Shaped Sofa',
      },
    ],
  },
  {
    title: 'BED ROOM',
    items: [
      {
        href: '/beds',
        src: 'cache/data/bed-with-storage/adolph-bed-with-side-storage/revised/revised/honey/updated/honey/1-750x650.jpg',
        alt: 'Beds',
        name: 'Beds',
      },
      {
        href: '/tv-units',
        src: 'cache/data/tv-units/melvina-tv-unit/revised/walnut/updated/1-750x650.jpg',
        alt: 'TV Units',
        name: 'TV Units',
      },
      {
        href: '/sofa-cum-beds',
        src: 'cache/data/sofa-beds/feltro-sofa-cum-bed/Printed+Dual+Fabric/cream-tropical/honey-finish/3-750x650.jpg',
        alt: 'Sofa Cum Beds',
        name: 'Sofa Cum Beds',
      },
      {
        href: '/kids-beds',
        src: 'cache/data/kids-bed-mdf/marina-kids-bed-with-storage/cardinal-red/updated/updated/1-750x650.jpg',
        alt: 'Kids Beds                                ',
        name: 'Kids Beds',
      },
      {
        href: '/bedside-tables',
        src: 'cache/data/bedside-tables/adolph-bedside-table/revised/walnut/updated/1-750x650.jpg',
        alt: 'Bedside Tables',
        name: 'Bedside Tables',
      },
      {
        href: '/mattress-online',
        src: 'cache/data/mattress/updated/ortho-memory-mattress/updated/single/6-inch/updated/dreamlux+updated/1-750x650.jpg',
        alt: 'Mattress',
        name: 'Mattress',
      },
      {
        href: '/trunk-blanket-boxes',
        src: 'cache/data/trunk-blanket-boxes-ws/boho-trunk-box/updated/honey/updated/1-750x650.jpg',
        alt: 'Trunk & Blanket Box',
        name: 'Trunk &amp; Blanket Box',
      },
      {
        href: '/dressing-tables',
        src: 'cache/data/dressing-tables/taryn-engineered-wood-dressing-table-with-storage-cabinet-and-drawers/exotic-teak/updated/1-750x650.jpg',
        alt: 'Dressing Tables',
        name: 'Dressing Tables',
      },
      {
        href: '/wardrobes',
        src: 'cache/data/wardrobe/cambrey-2-1-wardrobe/revised/honey/updated+new/1-750x650.jpg',
        alt: 'Wardrobes',
        name: 'Wardrobes',
      },
      {
        href: '/bedroom-lights',
        src: 'cache/data/home-decors/lamps-lighting/floor-lamps/beads-metal-floor-lamp/shoot-updated/001-750x650.jpg',
        alt: 'Bedroom Lights',
        name: 'Bedroom Lights',
      },
      {
        href: '/bed-sheets',
        src: 'cache/data/Suprint-Screen-Designs/s-5/azure-blue-floral-screen-print-120-tc-bed-sheet-with-pillow-cover/updated/1-750x650.jpg',
        alt: 'Bed Sheets',
        name: 'Bed Sheets',
      },
      {
        href: '/cushion-covers',
        src: 'cache/data/eyda/hand-woven-cotton-cushion-cover-set-of-2-brown-18-18-inch/updated/1-750x650.jpg',
        alt: 'Cushion Covers',
        name: 'Cushion Covers',
      },
    ],
  },
  {
    title: 'DINING & KITCHEN',
    items: [
      {
        href: '/6-seater-dining-table-sets',
        src: 'cache/data/dining-set/6-seater/adolph-6-seater-dining-set/revised/walnut/updated/1-750x650.jpg',
        alt: '6 Seater Dining Table Sets',
        name: '6 Seater Dining Table Sets',
      },
      {
        href: '/4-seater-dining-table-sets',
        src: 'cache/data/dining-set/4-seater/cohoon-4-seater-dining-set/revised/walnut/updated/1-750x650.jpg',
        alt: '4 Seater Dining Table Sets',
        name: '4 Seater Dining Table Sets',
      },
      {
        href: '/round-dining-sets',
        src: 'cache/data/dining-set/2-seater/clove-2-seater-dining-set/honey/updated/1-750x650.jpg',
        alt: 'Round Dining Table Sets',
        name: 'Round Dining Table Sets',
      },
      {
        href: '/marble-dining-table-sets',
        src: 'cache/data/China+Furniture/Light/valence-marble-top-6-seater-dining-table/4-750x650.jpg',
        alt: 'Marble Dining Table Sets',
        name: 'Marble Dining Table Sets',
      },
      {
        href: '/wine-racks',
        src: 'cache/data/wine-racks/ferris-wine-rack-walnut-finish/updated/1-750x650.jpg',
        alt: 'Wine Racks',
        name: 'Wine Racks',
      },
      {
        href: '/dining-chairs',
        src: 'cache/data/dining-chairs/alanis-dining-chair-set-of-2/honey-finish/1-750x650.jpg',
        alt: 'Dining Chairs',
        name: 'Dining Chairs',
      },
      {
        href: '/crockery-units',
        src: 'cache/data/kitchen-cabinet/greyson-kitchen-cabinet/Greyson+Kitchen+Cabinet+%28Exotic+Teak+Finish%29/2-750x650.jpg',
        alt: 'Crockery Units',
        name: 'Crockery Units',
      },
      {
        href: '/modular-kitchen-cabinet',
        src: 'cache/data/kitchen-cabinet/marty-kitchen-cabinet/updated/flowery-wenge-frosty-white-finish/1-750x650.jpg',
        alt: 'Kitchen Cabinet',
        name: 'Kitchen Cabinet',
      },
      {
        href: '/kitchen-shelves',
        src: 'cache/data/wall-cabinets/simone-wall-cabinet-revised/walnut/updated/1-750x650.jpg',
        alt: 'Kitchen Shelves',
        name: 'Kitchen Shelves',
      },
      {
        href: '/glass-dining-table-sets',
        src: 'cache/data/dining-set/6-seater/alfred-mozza-6-seater-dining-set/walnut/updated/1-750x650.jpg',
        alt: 'Glass Dining Table Sets',
        name: 'Glass Dining Table Sets',
      },
      {
        href: '/tissue-box',
        src: 'cache/data/homeware/tableware/Tissue+Box/oriental-wooden-tissue-paper-box/updated/1-750x650.jpg',
        alt: 'Tissue Box',
        name: 'Tissue Box',
      },
      {
        href: '/kitchen-organiser',
        src: 'cache/data/kitchen-rack/capper-kitchen-rack/updated/1-750x650.jpg',
        alt: 'Kitchen Organiser',
        name: 'Kitchen Organiser',
      },
    ],
  },
  {
    title: 'HOME DECOR',
    items: [
      {
        href: '/wall-shelves',
        src: 'cache/data/wall-shelves/grizzo-wall-shelf/walnut/updated/1-750x650.jpg',
        alt: 'Wall Shelves',
        name: 'Wall Shelves',
      },
      {
        href: '/home-temple',
        src: 'cache/data/home-temple/maurya-home-temple/revised/honey/updated/1-750x650.jpg',
        alt: 'Home Temple',
        name: 'Home Temple',
      },
      {
        href: '/wall-mirrors',
        src: 'cache/data/home-decors/wall-arts/wall-frames/bohemian-mirror-collage/honey/updated/1-750x650.jpg',
        alt: 'Wall Mirrors',
        name: 'Wall Mirrors',
      },
      {
        href: '/wall-clocks',
        src: 'cache/data/home-decors/clocks/wall-clocks/miller-cycle-shaped-metal-wall-clock/updated/b1-750x650.jpg',
        alt: 'Wall Clocks',
        name: 'Wall Clocks',
      },
      {
        href: '/tableware',
        src: 'cache/data/homeware/tableware/teapots/royal-crown-blue-ceramic-tea-pot-with-cup-set-of-2/updated/V-1-750x650.jpg',
        alt: 'Tableware',
        name: 'Tableware',
      },
      {
        href: '/figurines',
        src: 'cache/data/home-decors/miniatures-and-figurines/antique-gold-plated-swan-set-metal-showpiece-handicrafts-paradise/updated/1-750x650.jpg',
        alt: 'Figurines',
        name: 'Figurines',
      },
      {
        href: '/key-holders',
        src: 'cache/data/wall-shelves-mdf/jace-wall-shelf-with-key-holders-exotic-teak-finish/exotic-teak-finish/updated/1-750x650.jpg',
        alt: 'Key Holders',
        name: 'Key Holders',
      },
      {
        href: '/vases',
        src: 'cache/data/China+Lights/Plant/WSARTIFICIAL-76803/Update/2-750x650.jpg',
        alt: 'Vases',
        name: 'Vases',
      },
      {
        href: '/artificial-flowers',
        src: 'cache/data/China+Lights/Plant/WSARTIFICIAL-76759/new+images/1-810x702.jpg',
        alt: 'Artificial Flowers',
        name: 'Artificial Flowers',
      },
    ],
  },
  {
    title: 'LAMP & LIGHTING',
    items: [
      {
        href: '/table-lamps',
        src: 'cache/data/home-decors/lamps-lighting/table-lamp/hora-metal-golden-table-lamp/whitelampupdated/1-750x650.jpg',
        alt: 'Table Lamps',
        name: 'Table Lamps',
      },
      {
        href: '/floor-lamps',
        src: 'cache/data/home-decors/lamps-lighting/floor-lamps/beige-wooden-floor-lamp-with-white-shade/beige-wooden-floor-lamp-with-white-shade/1-750x650.jpg',
        alt: 'Floor Lamps',
        name: 'Floor Lamps',
      },
      {
        href: '/study-lamps',
        src: 'cache/data/lamps-lighting/the-night-watch--study-table-lamp-black-marble/1-750x650.jpg',
        alt: 'Study Lamps',
        name: 'Study Lamps',
      },
      {
        href: '/tripod-lamps',
        src: 'cache/data/lamps-lighting/floor-lamps/natural-brown-wooden-stick-tripod-floor-lamp/updated/KPL-1-750x650.jpg',
        alt: 'Tripod Lamps',
        name: 'Tripod Lamps',
      },
      {
        href: '/hanging-lights',
        src: 'cache/data/home-decors/lamps-lighting/Hanging+lamp/white-and-beige-mallavi-hanging-lamp/updated/updated/updated/OT-1-750x650.jpg',
        alt: 'Hanging Lights',
        name: 'Hanging Lights',
      },
      {
        href: '/gate-lights',
        src: 'cache/data/home-decors/jainsons+Lights/gracious-antique-gold-iron-gate-lights-without-bulb/1-750x650.jpg',
        alt: 'Gate Lights',
        name: 'Gate Lights',
      },
      {
        href: '/kitchen-lights',
        src: 'cache/data/home-decors/hanging-lights/symmetric-beige-wooden-series-hanging-light/updated/s1-750x650.jpg',
        alt: 'Kitchen Lights',
        name: 'Kitchen Lights',
      },
      {
        href: '/chandeliers',
        src: 'cache/data/China+Lights/WS-CHL-33100-5S/1-750x650.jpg',
        alt: 'Chandeliers',
        name: 'Chandeliers',
      },
      {
        href: '/pendant-lights',
        src: 'cache/data/home-decors/lamps-lighting/pendant-lights/brown-colour-starnet-bamboo-cylindrical-hanging-lamp-shade/updated/k1-750x650.jpg',
        alt: 'Pendant Lights',
        name: 'Pendant Lights',
      },
    ],
  },
  {
    title: 'FURNISHING',
    items: [
      {
        href: '/rugs-and-carpets',
        src: 'cache/data/home-decors/rugs/rhombus-geometric-pattern-hand-tufted-woolen-carpet-6-4-feet/revised/updated/C-1-750x650.jpg',
        alt: 'Carpets & Rugs',
        name: 'Carpets &amp; Rugs',
      },
      {
        href: '/cushion-covers',
        src: 'cache/data/eyda/hand-woven-cotton-cushion-cover-set-of-2-brown-18-18-inch/updated/1-750x650.jpg',
        alt: 'Cushion Covers',
        name: 'Cushion Covers',
      },
      {
        href: '/bed-sheets',
        src: 'cache/data/Suprint-Screen-Designs/s-5/azure-blue-floral-screen-print-120-tc-bed-sheet-with-pillow-cover/updated/1-750x650.jpg',
        alt: 'Bed Sheets',
        name: 'Bed Sheets',
      },
      {
        href: '/curtains',
        src: 'cache/data/wooden-street-curtains/CURTAINS/fossil-botanic-mist-floral-print-door-curtains-set-of-2-beige-7-feet-/1-750x650.jpg',
        alt: 'Curtains',
        name: 'Curtains',
      },
      {
        href: '/sofa-throws',
        src: 'cache/data/home-decors/sofa-throws/Sashaa/rust-soft-chenille-throw/updated/SW-1-750x650.jpg',
        alt: 'Sofa Throws',
        name: 'Sofa Throws',
      },
      {
        href: '/mattress',
        src: 'cache/data/mattress/updated/ortho-memory-mattress/updated/single/6-inch/updated/dreamlux+updated/1-750x650.jpg',
        alt: 'Mattress',
        name: 'Mattress',
      },
      {
        href: '/table-linen',
        src: 'cache/data/homeware/tableware/table-runner/multicolor-rangoli-velvet-printed-table-runner-72-14-inches/updated/updated/1-750x650.jpg',
        alt: 'Table Linen',
        name: 'Table Linen',
      },
      {
        href: '/sofa-covers',
        src: 'cache/data/home-decors/throwpillow/sunshine-ocher-yellow-with-pink-tassels-sofa-cover/1-750x650.jpg',
        alt: 'Sofa Covers',
        name: 'Sofa Covers',
      },
      {
        href: '/diwan-sets',
        src: 'cache/data/Clasiko-diwan/golden-tree-leaves-on-maroon-base-8-pcs-diwan-set/1-750x650.jpg',
        alt: 'Diwan Sets',
        name: 'Diwan Sets',
      },
    ],
  },
];
