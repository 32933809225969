// components/Header.tsx

import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import WsStaticImage from '@/components/common/WsStaticImage';
import CustomLink from '@/components/CustomLink';
import { AppDispatch, RootState } from '@/store';
import {
  addtoCart,
  clearCart,
  getCartProducts,
} from '@/store/features/cartSlice';
import { fetchRequestedSettings } from '@/store/features/commonSlice';
import { setMobileScreen } from '@/store/features/crmSlice';
import {
  getCustomerDetail,
  getCustomerDetails,
} from '@/store/features/customerSlice';
import { showLoginModal } from '@/store/features/loginSlice';
import { getIsUserAuthorized } from '@/store/features/userSlice';
import { getWishData, getWishlist } from '@/store/features/wishListsSlice';
import menuJson from '../../../../public/json/menu.json';
import Autocomplete from './Autocomplete';
import styles from './style.module.scss';
import Link from 'next/link';
// import { commonService } from '@/services/commonService';

const CartLink = dynamic(() => import('./cart-link'), { ssr: true });
const ProfileCard = dynamic(() => import('./profile-card'), { ssr: false });
const Header: React.FC = () => {
  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [press, setPress] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const wishlist = useSelector(getWishData);
  const [isHovered, setIsHovered] = useState(false);
  const [iAuthorized, setAuthorized] = useState(true);
  const userDetail = useSelector(getCustomerDetails);
  const isUserAuthorized = useSelector(getIsUserAuthorized);
  const { items } = useSelector((state: RootState) => state?.cart || {});

  useEffect(() => {
    if (isUserAuthorized) {
      dispatch(getWishlist());
      dispatch(getCustomerDetail());
      //   const details: any = localStorage?.getItem('customerDetails');
      //   setUserDetail(localStorage?.getItem('customerDetails'))
    }
    if (!iAuthorized && isUserAuthorized) {
      addLastCart();
      localStorage.removeItem('cartLead');
    }
    setAuthorized(isUserAuthorized);
    dispatch(setMobileScreen(false));
  }, [isUserAuthorized]);

  const addLastCart = async () => {
    dispatch(clearCart());
    if (items.length > 0) {
      const payload = {
        productId: items.map((u: any) => u.product_id)?.toString(),
        quantity: items.map((u: any) => u.quantity)?.toString(),
        comboId: items.map((u: any) => u.comboId ?? 0)?.toString(),
      };
      await dispatch(addtoCart(payload));
    }
    await dispatch(getCartProducts());
  };

  // useEffect(async () => {
  //   console.log(
  //     'dddddd',
  //     await dispatch(
  //       fetchRequestedSettings(['config_applycoupon', 'config_imgvrsn'])
  //     )
  //   );
  //   const cachedData = sessionStorage.getItem('requestedSettings');
  //   if (!cachedData) {
  //     dispatch(fetchRequestedSettings(['config_applycoupon', 'config_imgvrsn']))
  //       .unwrap()
  //       .then((response) => {
  //         sessionStorage.setItem('requestedSettings', JSON.stringify(response));
  //       })
  //       .catch((error) => {
  //         console.error('Failed to fetch data:', error);
  //       });
  //   }
  // }, [dispatch]);

  useEffect(() => {
    const cachedSettings = sessionStorage.getItem('requestedSettings');

    if (!cachedSettings) {
      // If no cached data is found, fetch and store it in sessionStorage
      dispatch(
        fetchRequestedSettings(['config_applycoupon', 'config_imgvrsn'])
      ).then((action: any) => {
        if (action.type === 'fetchRequestedSettings/fulfilled') {
          // Store the fetched data in sessionStorage
          sessionStorage.setItem(
            'requestedSettings',
            JSON.stringify(action.payload?.data)
          );
        }
      });
    }
  }, [dispatch]);
  const isSticky = () => {
    const header = document.querySelector('.header');
    const scrollTop = window.scrollY;
    scrollTop >= 100
      ? header?.classList?.add('header-fixed')
      : header?.classList?.remove('header-fixed');
  };
  const handleLoginModal = () => {
    dispatch(showLoginModal());
  };
  const handleClick = () => {
    setPress(true);
    setTimeout(() => {
      setPress(false);
    }, 100);
  };

  const [menuOpen, setMenuOpen] = useState(false); // State to manage menu visibility

  // Function to toggle menu
  // const toggleMenu = (open: boolean) => {
  //   setMenuOpen(open);
  // };

  // Function to close the menu
  const closeMenu = () => {
    setMenuOpen(false);
  };

  const Menu = () => {
    return (
      <>
        <ul
          className={`${styles.headerMenu} ${menuOpen ? styles.menuOpen : ''}`}
          onMouseLeave={() => setIsHovered(false)}
        >
          {menuJson?.map((item: any, index: number) =>
            item.category != 'Sale' ? (
              <li
                key={index}
                onMouseOver={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <CustomLink
                  href={item.url}
                  className={styles.menuLink}
                  onClick={closeMenu}
                >
                  <p>{item.category}</p>
                </CustomLink>
                <div className={styles.subMenu}>
                  <div className={styles.subMenucontent}>
                    <ul className={styles.submenuRow}>
                      {item.subcategories?.map(
                        (
                          subcategoryArray: any,
                          subcategoryArrayIndex: number
                        ) => (
                          <li
                            key={`sub_category_${subcategoryArrayIndex}`}
                            className={styles.subMenucard}
                          >
                            {subcategoryArray.map(
                              (
                                sub_category: any,
                                sub_categoryIndex: number
                              ) => (
                                <ul
                                  key={`sub_category_${subcategoryArrayIndex}_${sub_categoryIndex}`}
                                  className={styles.submenuList}
                                >
                                  <li>
                                    {sub_category.url ? (
                                      <CustomLink
                                        href={sub_category.url}
                                        className={styles.submenuLink}
                                        onClick={closeMenu}
                                      >
                                        <p>
                                          {sub_category.subcategory}
                                          {sub_category?.new && (
                                            <span
                                              style={{
                                                background: '#ec0909',
                                                color: '#fff',
                                                fontSize: '10px',
                                                lineHeight: '18px',
                                                marginLeft: '3px',
                                                padding: '1px 3px',
                                                borderRadius: '3px',
                                              }}
                                            >
                                              {sub_category?.new}
                                            </span>
                                          )}
                                        </p>
                                      </CustomLink>
                                    ) : (
                                      <div className={styles.submenuLink}>
                                        <p>{sub_category.subcategory}</p>
                                      </div>
                                    )}
                                  </li>
                                  {sub_category.items.map(
                                    (item: any, itemIndex: number) => (
                                      <li
                                        key={`item_${subcategoryArrayIndex}_${sub_categoryIndex}_${itemIndex}`}
                                      >
                                        <CustomLink
                                          href={item.url}
                                          className={styles.submenuLink}
                                          onClick={closeMenu}
                                        >
                                          <span>{item.name}</span>
                                        </CustomLink>
                                      </li>
                                    )
                                  )}
                                </ul>
                              )
                            )}
                          </li>
                        )
                      )}

                      {item.image && (
                        <li className={styles.subMenucard}>
                          <CustomLink
                            href={item.image.url}
                            className={styles.submenuImg}
                            onClick={closeMenu}
                          >
                            <WsStaticImage
                              src={item.image.src}
                              alt={item.image.alt}
                              width={295}
                              height={500}
                              classNameCls={'lazy-loading'}
                            />
                          </CustomLink>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </li>
            ) : (
              <li key={index}>
                <CustomLink href={item.url} className={styles.menuLink}>
                  <p>{item.category}</p>
                </CustomLink>
              </li>
            )
          )}
        </ul>
      </>
    );
  };

  return (
    <>
      <header className={styles.headerSection}>
        <div className={styles.headerInner}>
          <div
            className={styles.container1240}
            onMouseOver={() => setIsHovered(false)}
          >
            <div className={styles.headerContent}>
              <div className={styles.headerLeft}>
                <Link
                  href="/"
                  className={styles.headerLogo}
                  onClick={() => console.log('Logo clicked')}
                >
                  <WsStaticImage
                    src={'/images/header/new-logo.svg'}
                    alt="Home Furniture Online"
                    width={230}
                    height={55}
                    //loading="eager"
                    priority
                    quality={75}
                  />
                </Link>
              </div>
              <div className={styles.headerCenter}>
                <form>
                  <div className={styles.headerSearch}>
                    <Autocomplete
                      press={press}
                      sendSearch={() => {}}
                      placeholder="Search Products, Colors & More .."
                    />
                    <button
                      type="button"
                      onClick={handleClick}
                      aria-label="Search"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18.762"
                        height="20"
                        viewBox="0 0 18.762 20"
                      >
                        <path
                          d="M19.461,18.217l-4.625-4.951a8.191,8.191,0,0,0,1.841-5.191A7.972,7.972,0,0,0,8.83,0,7.972,7.972,0,0,0,.984,8.075,7.972,7.972,0,0,0,8.83,16.15a7.611,7.611,0,0,0,4.5-1.461l4.66,4.988a1,1,0,0,0,1.447.029A1.076,1.076,0,0,0,19.461,18.217ZM8.83,2.107a5.892,5.892,0,0,1,5.8,5.968,5.892,5.892,0,0,1-5.8,5.968,5.892,5.892,0,0,1-5.8-5.968A5.892,5.892,0,0,1,8.83,2.107Z"
                          transform="translate(-0.984)"
                          fill="#4a4a4a"
                        />
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
              <div className={styles.headerRight}>
                <ul className={styles.orderList}>
                  <li>
                    <CustomLink
                      href="/offline-furniture-store"
                      className={styles.orderLInk}
                    >
                      <>
                        <span>
                          <svg
                            width="28"
                            height="28"
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.5 13.0864V19.5C4.5 19.6989 4.57902 19.8897 4.71967 20.0303C4.86032 20.171 5.05109 20.25 5.25 20.25H18.75C18.9489 20.25 19.1397 20.171 19.2803 20.0303C19.421 19.8897 19.5 19.6989 19.5 19.5V13.0865"
                              stroke="#e57200"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M5.06573 3.75H18.9343C19.0973 3.75 19.2558 3.80309 19.3859 3.90124C19.516 3.99939 19.6106 4.13725 19.6554 4.29396L21 9H3L4.34458 4.29396C4.38936 4.13725 4.48396 3.99939 4.61408 3.90124C4.7442 3.80309 4.90274 3.75 5.06573 3.75Z"
                              stroke="#e57200"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M9 9V10.5C9 11.2956 8.68393 12.0587 8.12132 12.6213C7.55871 13.1839 6.79565 13.5 6 13.5C5.20435 13.5 4.44129 13.1839 3.87868 12.6213C3.31607 12.0587 3 11.2956 3 10.5V9"
                              stroke="#e57200"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M15 9V10.5C15 11.2956 14.6839 12.0587 14.1213 12.6213C13.5587 13.1839 12.7956 13.5 12 13.5C11.2044 13.5 10.4413 13.1839 9.87868 12.6213C9.31607 12.0587 9 11.2956 9 10.5V9"
                              stroke="#e57200"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M21 9V10.5C21 11.2956 20.6839 12.0587 20.1213 12.6213C19.5587 13.1839 18.7956 13.5 18 13.5C17.2044 13.5 16.4413 13.1839 15.8787 12.6213C15.3161 12.0587 15 11.2956 15 10.5V9"
                              stroke="#e57200"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </span>
                        <p>Stores</p>
                      </>
                    </CustomLink>
                  </li>
                  <li className={styles.profileDropdown}>
                    <span className={styles.orderLInk}>
                      <span>
                        <svg
                          width="23.673"
                          height="23.482"
                          viewBox="0 0 23.673 23.482"
                        >
                          <g transform="translate(37.837 11.741)">
                            <path
                              d="M23.673,11.818A11.836,11.836,0,1,0,3.831,20.556h0l.387.344H4.3l.646.473.215.129.689.43.172.086.818.43h.043a11.75,11.75,0,0,0,2.884.9h.086l.947.129h2.152l.947-.129h.086a11.75,11.75,0,0,0,2.841-.9h.086l.775-.387.172-.129.689-.43.215-.172.56-.43.129-.086.387-.344h0a11.793,11.793,0,0,0,3.831-8.651Zm-22.812,0a10.975,10.975,0,1,1,18.249,8.221l-.387-.215-3.658-1.808a.947.947,0,0,1-.516-.861V15.864l.258-.344a8.78,8.78,0,0,0,1.119-2.2,1.549,1.549,0,0,0,.9-1.42V10.355a1.549,1.549,0,0,0-.387-1.033V7.3a3.486,3.486,0,0,0-.818-2.539,4.927,4.927,0,0,0-3.788-1.334A4.95,4.95,0,0,0,8.006,4.8a3.486,3.486,0,0,0-.775,2.5V9.322a1.549,1.549,0,0,0-.387,1.033v1.506a1.549,1.549,0,0,0,.56,1.205,8.092,8.092,0,0,0,1.334,2.755v1.248a.947.947,0,0,1-.516.861l-3.4,1.851L4.477,20A10.932,10.932,0,0,1,.861,11.818Zm17.432,8.866-.473.3-.215.129-.6.344-.129.086a10.975,10.975,0,0,1-1.506.646h-.043l-.818.258h0l-.861.172H13.6l-.818.086H10.89l-.818-.086h-.043a10.933,10.933,0,0,1-1.679-.43H8.307l-.818-.3h0l-.732-.344H6.672l-.646-.43-.172-.129-.56-.387h0l3.4-1.851a1.808,1.808,0,0,0,.947-1.593V15.52l-.086-.129a7.188,7.188,0,0,1-1.291-2.669V12.55l-.129-.086a.732.732,0,0,1-.344-.6V10.355a.689.689,0,0,1,.258-.516l.129-.129V7.3h0a2.582,2.582,0,0,1,.6-1.894,4.1,4.1,0,0,1,3.185-1.076,3.851,3.851,0,0,1,3.142,1.076,2.625,2.625,0,0,1,.6,1.894V9.752l.129.129a.689.689,0,0,1,.258.516V11.9a.732.732,0,0,1-.6.646l-.215.086-.086.215A7.963,7.963,0,0,1,14.118,15l-.3.387-.086.129v1.593a1.808,1.808,0,0,0,.99,1.636l3.658,1.808h.086Z"
                              transform="translate(-37.837 -11.741)"
                              fill="#4a4a4a"
                            ></path>
                          </g>
                        </svg>
                      </span>

                      <p>
                        {isUserAuthorized && userDetail?.firstname
                          ? `Hi ${userDetail?.firstname?.length > 5 ? userDetail.firstname.substring(0, 5) + '...' : userDetail.firstname}`
                          : 'Profile'}
                      </p>
                    </span>
                    <div className={styles.profileCard}>
                      <ProfileCard />
                    </div>
                  </li>
                  <li>
                    {iAuthorized ? (
                      <CustomLink href="/wishlist" className={styles.orderLInk}>
                        <span>
                          <svg
                            width="22"
                            height="20.455"
                            viewBox="0 0 22 20.455"
                          >
                            <g transform="translate(0 -5.713)">
                              <path
                                d="M21.964,7.925c-.321-3.535-2.823-6.1-5.954-6.1a5.918,5.918,0,0,0-5.07,2.922A5.7,5.7,0,0,0,5.99,1.826C2.86,1.826.358,4.39.037,7.925a6.289,6.289,0,0,0,.187,2.318A9.942,9.942,0,0,0,3.27,15.326l7.665,6.955,7.8-6.955a9.943,9.943,0,0,0,3.046-5.083,6.3,6.3,0,0,0,.187-2.317Zm-1.011,2.124A9.092,9.092,0,0,1,18.164,14.7l-7.225,6.446L3.839,14.7a9.1,9.1,0,0,1-2.791-4.65A5.5,5.5,0,0,1,.872,8.06l.006-.043C1.153,4.92,3.3,2.672,5.99,2.672a4.96,4.96,0,0,1,4.557,3.18l.39.924.39-.924A5.112,5.112,0,0,1,16.01,2.673c2.687,0,4.837,2.248,5.118,5.385A5.489,5.489,0,0,1,20.953,10.049Z"
                                transform="translate(0 3.887)"
                                fill="#4a4a4a"
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <p>Wishlist ({wishlist?.length ?? 0})</p>
                      </CustomLink>
                    ) : (
                      <span
                        className={styles.orderLInk}
                        onClick={handleLoginModal}
                      >
                        <span>
                          <svg
                            width="22"
                            height="20.455"
                            viewBox="0 0 22 20.455"
                          >
                            <g transform="translate(0 -5.713)">
                              <path
                                d="M21.964,7.925c-.321-3.535-2.823-6.1-5.954-6.1a5.918,5.918,0,0,0-5.07,2.922A5.7,5.7,0,0,0,5.99,1.826C2.86,1.826.358,4.39.037,7.925a6.289,6.289,0,0,0,.187,2.318A9.942,9.942,0,0,0,3.27,15.326l7.665,6.955,7.8-6.955a9.943,9.943,0,0,0,3.046-5.083,6.3,6.3,0,0,0,.187-2.317Zm-1.011,2.124A9.092,9.092,0,0,1,18.164,14.7l-7.225,6.446L3.839,14.7a9.1,9.1,0,0,1-2.791-4.65A5.5,5.5,0,0,1,.872,8.06l.006-.043C1.153,4.92,3.3,2.672,5.99,2.672a4.96,4.96,0,0,1,4.557,3.18l.39.924.39-.924A5.112,5.112,0,0,1,16.01,2.673c2.687,0,4.837,2.248,5.118,5.385A5.489,5.489,0,0,1,20.953,10.049Z"
                                transform="translate(0 3.887)"
                                fill="#4a4a4a"
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <p>Wishlist (0)</p>
                      </span>
                    )}
                  </li>
                  <li>
                    <CartLink />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={`${styles.headermenuContent} header`}>
            <div className={styles.container1240}>
              <a className={styles.CloseMenu}>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_950_723"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                  >
                    <rect
                      x="0.292839"
                      y="0.64917"
                      width="24"
                      height="24"
                      fill="#D9D9D9"
                    />
                  </mask>
                  <g mask="url(#mask0_950_723)">
                    <path
                      d="M12.2928 14.0492L7.39284 18.9492C7.20951 19.1326 6.97617 19.2242 6.69284 19.2242C6.4095 19.2242 6.17617 19.1326 5.99284 18.9492C5.8095 18.7659 5.71784 18.5326 5.71784 18.2492C5.71784 17.9659 5.8095 17.7326 5.99284 17.5492L10.8928 12.6492L5.99284 7.74922C5.8095 7.56589 5.71784 7.33255 5.71784 7.04922C5.71784 6.76589 5.8095 6.53255 5.99284 6.34922C6.17617 6.16589 6.4095 6.07422 6.69284 6.07422C6.97617 6.07422 7.20951 6.16589 7.39284 6.34922L12.2928 11.2492L17.1928 6.34922C17.3762 6.16589 17.6095 6.07422 17.8928 6.07422C18.1762 6.07422 18.4095 6.16589 18.5928 6.34922C18.7762 6.53255 18.8678 6.76589 18.8678 7.04922C18.8678 7.33255 18.7762 7.56589 18.5928 7.74922L13.6928 12.6492L18.5928 17.5492C18.7762 17.7326 18.8678 17.9659 18.8678 18.2492C18.8678 18.5326 18.7762 18.7659 18.5928 18.9492C18.4095 19.1326 18.1762 19.2242 17.8928 19.2242C17.6095 19.2242 17.3762 19.1326 17.1928 18.9492L12.2928 14.0492Z"
                      fill="black"
                    />
                  </g>
                </svg>
              </a>

              <Menu />
            </div>
          </div>
        </div>
      </header>
      <div
        className={` ${isHovered ? styles.hovered : styles.notHovered}`}
        onMouseEnter={() => setIsHovered(false)}
        onMouseOver={() => setIsHovered(false)}
      ></div>
    </>
  );
};

export default Header;
