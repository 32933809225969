import WsStaticImage from '@/components/common/WsStaticImage';
import Link from 'next/link';
import React from 'react';
import AskExpert from '../AskExpert/AskExpert';
import styles from './style.module.scss'; // Adjust the path as necessary

const FooterMenu = () => {
  const data = [
    {
      title: 'Our Company',
      links: [
        { text: 'About Us', href: '/about-us' },
        { text: 'Career', href: '/careers' },
        { text: 'Media', href: '/media' },
        { text: 'Blog', href: '/blog' },
        { text: 'Our Stores', href: '/offline-furniture-store' },
        { text: 'Customer Stories', href: '/customer-stories' },
        { text: 'Investor Relations', href: '/investor-relations' },
      ],
    },
    {
      title: 'Useful Links',
      links: [
        { text: 'Custom Furniture', href: '/custom-furniture' },
        { text: 'Exporters', href: '/furniture-exporters' },
        // { text: 'Buy in Bulk', href: '/furniture-packages' },
        { text: 'Hotel Furniture', href: '/hotel-furniture' },
        { text: 'Delivery Location', href: '/locations' },
        { text: 'Sitemap', href: '/sitemap' },
      ],
    },
    {
      title: 'Shop By Room',
      links: [
        { text: 'Living Room', href: '/living-room-furniture' },
        { text: 'Bedroom', href: '/bedroom-furniture' },
        { text: 'Dining Room', href: '/dining-room-furniture' },
        { text: 'Kids Room', href: '/kids-furniture' },
        { text: 'Modular Furniture', href: '/modular-furniture' },
        { text: 'Modular Kitchen', href: '/modular-kitchen-designs' },
        { text: 'Modular Wardrobe', href: '/modular-wardrobe-designs' },
      ],
    },
    {
      title: 'Partners',
      links: [
        { text: 'Become a Franchise', href: '/furniture-franchise' },
        { text: 'Sell on WoodenStreet', href: '/sell-on-woodenstreet' },
        {
          text: 'Design Partner',
          href: '/design-services/partner-to-win?architect-interior_menu',
        },
      ],
      extraSection: {
        title: 'Trusted By',
        links: [
          {
            text: 'QRO Certified',
            href: '/footer/qro.pdf',
            target: '_blank',
            rel: 'noreferrer',
          },
          {
            text: 'Ukcert Certified',
            href: '/footer/ukcert.pdf',
            target: '_blank',
            rel: 'noreferrer',
          },
        ],
        images: [
          {
            src: '/images/footer/Qro-logo.png',
            alt: 'Qro-logo',
            href: '/footer/qro.pdf',
            target: '_blank',
            rel: 'noreferrer',
          },
          {
            src: '/images/footer/Ukcert-logo.png',
            alt: 'Ukcert-logo',
            href: '/footer/ukcert.pdf',
            target: '_blank',
            rel: 'noreferrer',
          },
        ],
      },
    },
    {
      title: 'Need Help',
      links: [
        { text: 'Help Center', href: '/help-center' },
        { text: 'Contact Us', href: '/support-form' },
        { text: 'Track your order', href: '/track-order-by-email' },
      ],
    },
  ];

  const mediaLinks = [
    {
      imageSrc: '/images/footer/insta.svg',
      alt: 'Wooden Street Instagram profile',
      link: 'https://www.instagram.com/woodenstreet/',
    },
    {
      imageSrc: '/images/footer/facebook.svg',
      alt: 'Wooden Street Facebook profile',
      link: 'https://www.facebook.com/woodenstreet/',
    },
    {
      imageSrc: '/images/footer/twitter.svg',
      alt: 'Wooden Street twitter profile',
      link: 'https://x.com/Wooden_street',
    },
    {
      imageSrc: '/images/footer/pin.svg',
      alt: 'Wooden Street Pinterest profile',
      link: 'https://in.pinterest.com/woodenstreet/',
    },
    {
      imageSrc: '/images/footer/youtube.svg',
      alt: 'Wooden Street Youtube profile',
      link: 'https://www.youtube.com/c/woodenstreetofficial/',
    },
    {
      imageSrc: '/images/footer/linkdin.svg',
      alt: 'Wooden Street linkedin profile',
      link: 'https://in.linkedin.com/company/wooden-street/',
    },
  ];

  return (
    <div className="wdRow wdRow-cols-2 wdRow-cols-sm-2 wdRow-cols-md-3 wdRow-cols-lg-5">
      {data.map((menu, index) => (
        <div key={index} className="wdCol">
          <ul className={styles.footermenu}>
            <li>
              <div className={styles['footer-head']}>{menu.title}</div>
            </li>

            {menu.links.map((link: any, linkIndex: number) => (
              <li key={linkIndex}>
                <Link
                  href={link.href}
                  className={styles.footermenuLink}
                  onClick={(e) => link?.handleClick && link.handleClick(e)}
                >
                  {link.text}
                </Link>
              </li>
            ))}

            {menu.extraSection && (
              <>
                <li style={{ marginTop: '20px', marginBottom: '10px' }}>
                  <div className={styles['footer-head']}>
                    {menu.extraSection.title}
                  </div>
                </li>

                {menu.extraSection.links.map((link: any, linkIndex: number) => (
                  <li key={linkIndex}>
                    <Link
                      href={link.href}
                      className={styles.footermenuLink}
                      target={link.target}
                      rel={link.rel}
                    >
                      {link.text}
                    </Link>
                  </li>
                ))}

                {menu.extraSection.images.map(
                  (link: any, linkIndex: number) => (
                    <li
                      key={linkIndex}
                      style={{
                        display: 'inline-block',
                        paddingRight: '10px',
                        marginTop: '10px',
                      }}
                    >
                      <Link
                        href={link.href}
                        className={styles.footermenuImageLink}
                        target={link.target}
                        rel={link.rel}
                      >
                        <WsStaticImage
                          src={link.src}
                          alt={link.alt}
                          style={link.style}
                          height={47}
                          width={55}
                          quality={75}
                          // loading="lazy"
                        />
                      </Link>
                    </li>
                  )
                )}
              </>
            )}
          </ul>
          {menu?.title === 'Need Help' && (
            <>
              <AskExpert />
              <ul className={styles.footersocial}>
                {mediaLinks.map((media, i) => (
                  <li key={i}>
                    <Link
                      href={media.link}
                      target="_blank"
                      rel="nofollow noreferrer"
                    >
                      <WsStaticImage
                        src={media.imageSrc}
                        alt={media.alt}
                        width={30}
                        height={30}
                      />
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default FooterMenu;
