import React, { useEffect } from 'react';
import WsStaticImage from '@/components/common/WsStaticImage';
import { RootState } from '@/store';
import { hideLoginModal, resetLoginModal } from '@/store/features/loginSlice';
import dynamic from 'next/dynamic';
import { useDispatch, useSelector } from 'react-redux';
import styles from './style.module.scss';
import { usePathname } from 'next/navigation';
import { getCustomerDetails } from '@/store/features/customerSlice';
const LoginOTPForm = dynamic(() => import('./login-via-otp'));
const WsModal = dynamic(() => import('@/components/common/WsModal'));
const LoginForm = dynamic(() => import('./login-form'));
const ForgotPassword = dynamic(() => import('./forgot'));
const Otp = dynamic(() => import('./otp'));
const NewPassword = dynamic(() => import('./new-password'));
const Signup = dynamic(() => import('./signup'));
const Social = dynamic(() => import('./social'));
const FistSignup = dynamic(() => import('./fistSignup'));
interface Props {
  isMobile?: boolean;
  // Define the props for your component here
}
const LoginModal: React.FC<Props> = ({
  isMobile = false,
  /* Destructure props here */
}) => {
  const {
    showModal,
    showSignUp,
    showForgot,
    showNewPassword,
    showOtp,
    showLogin,
    showMobileLogin,
    showFistSignUp,
  } = useSelector((state: RootState) => state?.loginModal);

  const dispatch = useDispatch();
  const customer = useSelector(getCustomerDetails);

  const handleCloseModal = () => {
    dispatch(resetLoginModal());
    dispatch(hideLoginModal());
  };

  const pathName = usePathname();
  useEffect(() => {
    const smartechData = {
      type: 'dispatch',
      event: 'Page_browse',
      data: { page_url: `${process.env.NEXT_PUBLIC_BASE_URL}${pathName}` },
    };
    if (typeof window.smartech === 'function') {
      console.log(
        'Smartech Event Triggered:',
        smartechData.event,
        smartechData.data
      );
      window.smartech('dispatch', smartechData.event, smartechData.data);
      window.smartech('identify', customer?.telephone);
    } else {
      console.log('Smartech function is not available');
    }
  }, [pathName]);

  return (
    <>
      <WsModal
        isOpen={showModal}
        // size='x-large'
        onClose={handleCloseModal}
        noPadding
        mainClass={'login-modal'}
        zIndex={1100}
      >
        <div className={`${styles['sign-up']}`} id="login-pop">
          {!isMobile && (
            <div className={styles['left']}>
              {showSignUp ? (
                <WsStaticImage
                  width={380}
                  height={543}
                  src={'/images/login/login-bg2.jpg'}
                  alt="login-bg"
                  id="sideImage"
                  quality={75}
                  // loading="lazy"
                />
              ) : (
                <WsStaticImage
                  width={380}
                  height={543}
                  src={'/images/login/login-bg2.jpg'}
                  alt="login-bg"
                  id="sideImage"
                  quality={75}
                  // loading="lazy"
                />
              )}
            </div>
          )}
          <div className={styles['right']}>
            {showLogin && <LoginForm />}

            {showMobileLogin && <LoginOTPForm />}

            {showForgot && <ForgotPassword />}

            {showOtp && <Otp isMobile={isMobile} />}

            {showNewPassword && <NewPassword isMobile={isMobile} />}

            {showSignUp && <Signup />}
            {(showNewPassword || showForgot || showOtp) && isMobile ? (
              <></>
            ) : (
              <Social />
            )}
          </div>
        </div>
      </WsModal>
      {isMobile && <FistSignup isShow={showFistSignUp} />}
    </>
  );
};

export default LoginModal;
