import WsStaticImage from '@/components/common/WsStaticImage';
import { getIsUserAuthorized } from '@/store/features/userSlice';
import CustomLink from '@/components/CustomLink';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './style.module.scss';

const links = [
  {
    href: 'tel:+91-9314444747',
    src: '/images/header/top-phone.svg',
    alt: 'phone',
    text: '+91-9314444747',
    width: 17,
    height: 17,
  },
  {
    href: 'furniture-franchise',
    src: '/images/header/Franchise.svg',
    alt: 'franchise',
    text: 'Become a Franchise',
    width: 23,
    height: 23,
  },
  {
    src: '/images/header/TrackOrder.svg',
    alt: 'track order',
    text: 'Track Order',
    width: 23,
    height: 23,
  },
  {
    href: '/help-center',
    src: '/images/header/help-center.svg',
    alt: 'help center',
    text: 'Help Center',
    width: 17,
    height: 17,
  },
];

export default function RightLinks() {
  const [trackHref, setTrackHref] = useState('/track-order-by-email');

  const isUserAuthorized = useSelector(getIsUserAuthorized) ?? false;

  useEffect(() => {
    setTrackHref(isUserAuthorized ? '/order-history' : '/track-order-by-email');
  }, [isUserAuthorized]);

  return (
    <ul className={styles.topheaderRight}>
      {links.map(({ href, src, alt, text, width, height }, index) => (
        <li key={index}>
          <CustomLink
            href={href || (text === 'Track Order' ? trackHref : '#')}
            className={styles.topheaderLinks}
          >
            <WsStaticImage
              src={src}
              alt={alt}
              width={width}
              height={height}
              loading="eager"
              priority
            />
            <p>{text}</p>
          </CustomLink>
        </li>
      ))}
    </ul>
  );
}
