import React from 'react';
import styles from './style.module.scss';
import Link from 'next/link';
import WsStaticImage from '@/components/common/WsStaticImage';

interface WhatsappCallProps {
  // data: React.ReactNode[];
}
const WhatsappCall: React.FC<WhatsappCallProps> = () => {
  return (
    <>
      <Link
        href={
          'https://api.whatsapp.com/send?phone=919314444747&text=Hi,%20I%20would%20like%20to%20discuss%20about%20the%20furniture%20and%20other%20details.%20Kindly%20assist!'
        }
        target="_blank"
        className={styles['whatsapp-call']}
      >
        <WsStaticImage
          src={'/images/whatsapp.svg'}
          alt="whatsapp"
          width={30}
          height={30}
          quality={75}
          // loading="lazy"
        />
      </Link>
    </>
  );
};

export default WhatsappCall;
