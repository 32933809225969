import React from 'react';
import styles from './style.module.scss';
import Link from 'next/link';
import { useRouter } from 'next/router';

import FooterStrip from '../strip-list';
import ClientSlider from './client-slider';
import FooterMenu from './footer-menu';
import WsStaticImage from '@/components/common/WsStaticImage';

const Footer = () => {
  const router = useRouter();

  return (
    <>
      <FooterStrip />

      <div className={styles.footerwrapper}>
        {router.asPath != '/' && <ClientSlider />}

        <section className={styles.footerSection}>
          <div className={styles.footerInner}>
            <div className="container-1240">
              <div className={styles.footerTop}>
                <FooterMenu />
              </div>
              <div className={styles.footerMiddle}>
                <div className="wdRow ">
                  <div className="wdCol-lg-5 wdCol-md-4">
                    <div className={styles.footerlocation}>
                      <p>
                        <span>Delivery Across India : </span> Ahmedabad,
                        Bangalore, Bhopal, Chandigarh, Chennai, Coimbatore,
                        Faridabad, Ghaziabad, Goa, Gurgaon, Hyderabad, Indore,
                        Jaipur and{' '}
                        <Link className="text-link-primary" href="/locations">
                          More Cities
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="wdCol-lg-4 wdCol-md-4">
                    <div className={styles.footerpay}>
                      <p>We accept</p>
                      <ul>
                        <li>
                          <WsStaticImage
                            src={'/images/footer/footer-cards.png'}
                            alt="footer"
                            width={500}
                            height={60}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="wdCol-lg-3 wdCol-md-4">
                    <div className={styles.footerpay}>
                      <p>Download App</p>
                      <ul>
                        <li>
                          <Link
                            target="_blank"
                            className={styles.footerapp}
                            href="https://play.google.com/store/apps/details?id=com.woodenstreet"
                            rel="nofollow noreferrer"
                          >
                            <WsStaticImage
                              src={'/images/footer/app-icon.png'}
                              alt="android"
                              width={150}
                              height={60}
                            />
                          </Link>
                          <Link
                            target="_blank"
                            className={styles.footerapp}
                            href="https://apps.apple.com/in/app/wooden-street/id1162068644?ls=1"
                            rel="nofollow noreferrer"
                          >
                            <WsStaticImage
                              src={'/images/footer/ios-icon.png'}
                              alt="ios"
                              width={150}
                              height={60}
                            />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.footerBottom}>
                <div className={styles.footerBottomleft}>
                  <Link href="/" className={styles.footerLogo}>
                    <WsStaticImage
                      src={'/images/footer/logofooter.svg'}
                      alt="logo"
                      width={100}
                      height={60}
                    />
                  </Link>
                  <div className={styles.footercopyRight}>
                    <ul>
                      <li>
                        <Link href="/terms-of-use">Terms of Use</Link>
                      </li>
                      <li>
                        <Link href="/security-and-privacy">Security</Link>
                      </li>
                      <li>
                        <Link href="/return-and-refunds">Return & Refund</Link>
                      </li>
                      <li>
                        <Link href="/payment-policy">Payment Policy</Link>
                      </li>
                      <li>
                        <Link href="/grievance-redressal">Grievance Cell</Link>
                      </li>
                    </ul>
                    <p>
                      © 2015-{new Date().getFullYear()} Woodenstreet.com. All
                      rights reserved.
                    </p>
                    <p>The Woodenstreet Furnitures Private Limited</p>
                  </div>
                </div>
                <div className={styles.footerBottomright}>
                  <div className={styles.footerRegisteredOffice}>
                    <p>
                      <b>Registered Office</b>
                      <br />
                      <b>The Woodenstreet Furniture's Pvt.Ltd.-</b>
                      <span>101-104, Luhadia Tower,</span>
                      <br />
                      <span>Ashok Marg, C Scheme, Jaipur-302001.</span>
                      <br />
                      <b>Corporate Identity Number:</b>
                      <span>U36100RJ2015PTC047992</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Footer;
