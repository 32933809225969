'use client';

import { ZendeskProvider, useZendesk } from 'react-use-zendesk';
import { useEffect, useState } from 'react';

const AskExpert = ({
  svgIcon,
  isColor,
  btnText,
}: {
  svgIcon?: any;
  isColor?: string;
  btnText?: string;
}) => {
  const [loaded, setLoaded] = useState(false);
  const apiKey = process.env.NEXT_PUBLIC_ZENDESK_API_KEY || '';
  useEffect(() => {
    const handleInteraction = (): void => {
      setLoaded(true);
      cleanupListeners();
    };

    const cleanupListeners = (): void => {
      document.removeEventListener('mousemove', handleInteraction);
      document.removeEventListener('touchstart', handleInteraction);
    };

    if (process.env.NEXT_PUBLIC_APP_MODE === 'production') {
      document.addEventListener('mousemove', handleInteraction);
      document.addEventListener('touchstart', handleInteraction);
    }

    return cleanupListeners;
  }, []);

  const Button = ({ text }: { text: string }) => {
    const { open } = useZendesk();
    return (
      <button style={{ color: isColor || 'black' }} onClick={() => open()}>
        {text}
      </button>
    );
  };
  if (!loaded) {
    return null;
  }
  return (
    <ZendeskProvider apiKey={apiKey}>
      {svgIcon}
      <Button text={btnText ?? 'Ask Expert'} />
    </ZendeskProvider>
  );
};

export default AskExpert;
