import WsStaticImage from '@/components/common/WsStaticImage';
import React from 'react';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import styles from './style.module.scss';
import CustomLink from '@/components/CustomLink';

const ClientSlider: React.FC = () => {
  const data = [
    { href: '/media', src: '/images/client/media-1.jpg', alt: 'media' },
    { href: '/media', src: '/images/client/media-2.jpg', alt: 'media' },
    { href: '/media', src: '/images/client/media-3.jpg', alt: 'media' },
    { href: '/media', src: '/images/client/media-4.jpg', alt: 'media' },
    { href: '/media', src: '/images/client/media-5.jpg', alt: 'media' },
    { href: '/media', src: '/images/client/media-6.jpg', alt: 'media' },
    { href: '/media', src: '/images/client/media-7.jpg', alt: 'media' },
    { href: '/media', src: '/images/client/media-8.jpg', alt: 'media' },
    { href: '/media', src: '/images/client/media-9.jpg', alt: 'media' },
    { href: '/media', src: '/images/client/media-10.jpg', alt: 'media' },
    { href: '/media', src: '/images/client/media-11.jpg', alt: 'media' },
    { href: '/media', src: '/images/client/media-12.jpg', alt: 'media' },
  ];

  return (
    <section className={styles.clientsection}>
      <div className="container-1200">
        <div className={styles.clientinner}>
          <div className="clientslider">
            <CustomLink href="/media">
              <Swiper
                spaceBetween={0}
                centeredSlides={true}
                autoplay={{
                  delay: 1,
                  disableOnInteraction: true,
                }}
                speed={6000}
                loop={true}
                slidesPerView={'auto'}
                freeMode={true}
                allowTouchMove={false}
                breakpoints={{
                  640: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 5,
                    spaceBetween: 12,
                  },
                  1024: {
                    slidesPerView: 7,
                    spaceBetween: 12,
                  },
                }}
                modules={[Autoplay]}
                className="mySwiper"
                wrapperClass={'clientsliderWrapper'}
              >
                {data.map((media, index) => (
                  <SwiperSlide key={index}>
                    <div className={styles.clientcardimg}>
                      <WsStaticImage
                        src={media.src}
                        alt={media.alt}
                        width={200}
                        height={100}
                        // loading="lazy"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </CustomLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientSlider;
