// components/SkeletonLoader.tsx
import React from 'react';
import styles from './style.module.scss';

interface skeletonProps {
  height?: number;
  width?: number;
}

const SkeletonLoader: React.FC<skeletonProps> = () => {
  return <div className={`${styles.skeleton} ws-skeleton`}></div>;
};

export default SkeletonLoader;
