import { getCookie, setCookie } from 'cookies-next';
import dynamic from 'next/dynamic';
import CustomLink from '@/components/CustomLink';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import WsStaticImage from '@/components/common/WsStaticImage';
import { AppDispatch } from '@/store';
import { checkPin, getFastUpdate } from '@/store/features/commonSlice';
import {
  getStoreLocation,
  getStoreLocationDetails,
  getStoresPincode,
  setStorePincode,
} from '@/store/features/storeLocationSlice';
import { decodeHTMLEntities } from '@/utils/common';
import RightLinks from './right-links';
import styles from './style.module.scss';
const FreeDelivery = dynamic(() => import('./free-delivery'));
const WsButton = dynamic(() => import('@/components/common/WsButton'));

const DiscountStrip: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isFastUpdate = useSelector(getFastUpdate);
  const [loading, setLoading] = useState(false);
  const [pinError, setPinError] = useState(false);
  const [showView, setShowView] = useState<any>({});
  const [address, setAddress] = useState<string>('');
  const [pinText, setPinText] = useState<string>('');
  const [isTextEnable, setEnabletext] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [location, setLocation] = useState<string[]>([]);
  const [isShowPinMenu, setShowPinMenu] = useState(false);
  const locs = useSelector(getStoreLocationDetails);
  const stores = useSelector(getStoresPincode);
  const [isShowRelateStore, setShowRelateStore] = useState(false);
  const [pinLable, setPinLable] = useState<string>('Enter Pincode');
  const [placeHolder, setPlaceHolder] = useState<string>('313001,Udaipur');
  const [isPinned, setIsPinned] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      setMaxHeight(contentRef.current.scrollHeight + 350);
    }
  }, [isPinned]);

  useEffect(() => {
    setLocations(); // getLocation();
  }, [stores, locs]);
  const setLocations = () => {
    let city = getCookie('city');
    const pin = getCookie('pincode');
    const prn = pinLable.split(' , ');
    if (!city && prn?.[0] === pin && prn?.[1]) city = prn[1];
    if (pin && city) {
      setPinLable(pin + ' , ' + city);
      setPlaceHolder(pin + ', ' + city);
      if (localStorage.location) {
        const location = JSON.parse(localStorage.location);
        if (location?.length > 0) {
          if (!location[0]?.store_address) {
            setLocation([]);
            return;
          }
          if (location?.length > 5) {
            setShowView({
              show: true,
              href: '/furniture-store-' + location[0]?.city?.toLowerCase(),
            });
            location.splice(6, location.length - 1);
            setLocation(location);
          } else setLocation(location);
          setShowRelateStore(true);
        }
      } else setLocation([]);
    } else {
      setLocation([]);
      setEnabletext(false);
      setPinLable('Enter Pincode');
      setPlaceHolder('313001,Udaipur');
    }
  };

  useEffect(() => {
    const exp = localStorage.getItem('expressSession');
    if (exp) {
      const espJson = JSON.parse(exp);
      const txt = ': ' + espJson.pincode + ', ' + espJson.city;
      setAddress(txt);
      const pin = getCookie('pincode');
      if (!pin || (isFastUpdate && pin !== espJson.pincode)) {
        setPinLable(espJson.pincode + ' , ' + espJson.city);
        setPlaceHolder(espJson.pincode + ', ' + espJson.city);
        handleSubmit(false, espJson.pincode);
      }
    }
  }, [isFastUpdate]);

  const handlePinMenu = (e: any) => {
    e?.preventDefault();
    setPinError(false);
    setShowPinMenu(!isShowPinMenu);
    setPlaceHolder(pinLable);
    setPinText('');
    const city = getCookie('city');
    const pin = getCookie('pincode');
    if (pin && city) setEnabletext(false);
    else setEnabletext(true);
    setIsPinned((prevState: any) => !prevState);
  };
  const handlePinText = (e: any) => {
    e.preventDefault();
    const text = e.target.value;
    setLoading(false);
    setPinError(false);
    if (pinText?.length < 6 || text.length < 6) setPinText(text);
  };
  const handleEnableText = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setPinText('');
    setPlaceHolder('');
    setLoading(false);
    setPinError(false);
    setEnabletext(!isTextEnable);
  };

  const handleSubmit = async (e: any, pin?: any, flag?: boolean) => {
    console.log('handlesb call');
    try {
      e && e?.preventDefault();
      setLoading(true);
      if (pinText?.length === 6 || pin?.length === 6) {
        setEnabletext(false);
        const res = await dispatch(
          getStoreLocation({ pin: !pinText ? pin : pinText, isCity: flag })
        );
        if (res?.payload?.length > 0) {
          setShowRelateStore(true);
          setPinText('');
          setLocations();
        } else if (!flag) {
          setLocation([]);
          await dispatch(checkPin({ pincode: !pinText ? pin : pinText }))
            .then((res: any) => {
              if (res?.payload?.code === 200) {
                const row = res?.payload?.data?.rows;
                if (row?.city && row?.pincode) {
                  setCookie('city', row.city);
                  if (Number(row.pincode)) {
                    setCookie('pincode', row.pincode);
                    dispatch(setStorePincode(row.pincode));
                  }
                  row.state && setCookie('state', row.state);
                  setPinLable(row.pincode + ' , ' + row.city);
                  setPlaceHolder(row.pincode + ', ' + row.city);
                  setPinText('');
                } else {
                  setPinError(true);
                  setPlaceHolder('');
                  setEnabletext(true);
                  setShowRelateStore(true);
                  setPinLable('Enter Pincode');
                }
              }
            })
            .catch((err: any) => console.log('error:', err));
        }
      } else setPinError(true);
    } catch (error) {
      setPinError(true);
      console.log('handleSubmit ~ error:', error);
    }
    setLoading(false);
  };
  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className={styles.topheaderSection}>
      <div className="container-1240">
        <div className={styles.topheaderInner}>
          <ul className={styles.topheaderLeft}>
            <li>
              <div className={styles.topLocation}>
                <WsStaticImage
                  src={'/images/header/location-icon.svg'}
                  alt="media"
                  width={23}
                  height={23}
                  //loading="eager"
                  priority
                />
                <p>
                  <span> Find a Store </span> - {pinLable}
                </p>
                <a className={styles.editIcon} onClick={handlePinMenu}>
                  <WsStaticImage
                    src={'/images/header/topedit-img.svg'}
                    alt="media"
                    width={18}
                    height={18}
                    //loading="eager"
                    priority
                  />
                </a>
              </div>

              <div
                className={`${styles.topheaderapply} ${isPinned ? styles.pinned : ''}`}
                style={{
                  maxHeight: isPinned ? `${maxHeight}px` : '0',
                  overflow: 'hidden',
                  transition: 'max-height .8s ease-in-out',
                }}
              >
                <div className={styles['inner-pinned']} ref={contentRef}>
                  <a className={styles.Closeapply} onClick={handlePinMenu}>
                    <WsStaticImage
                      src={'/images/header/apply-close.svg'}
                      alt="media"
                      width={25}
                      height={25}
                      //loading="eager"
                      priority
                    />
                  </a>
                  <form onSubmit={handleSubmit}>
                    <div className={styles.topapplycard}>
                      <label className={styles.field__label}>Pincode</label>
                      <input
                        id="search-user"
                        className={styles.formControl}
                        type="number"
                        disabled={!isTextEnable}
                        value={pinText}
                        onChange={handlePinText}
                        placeholder={
                          placeHolder ?? pinLable ?? '313001,Udaipur'
                        }
                      />
                      <WsButton
                        type={isTextEnable ? 'submit' : 'button'}
                        disabled={loading}
                        isLoading={isTextEnable ? loading : false}
                        className={styles.applybtn}
                        onClick={!isTextEnable ? handleEnableText : undefined}
                      >
                        {isTextEnable ? 'Apply' : 'Change'}
                      </WsButton>
                    </div>
                    {pinError && (
                      <p className={styles.pinError}>
                        {pinText?.length < 6
                          ? 'Please enter a valid Pincode'
                          : 'Delivery option not available on requested area.'}
                      </p>
                    )}
                  </form>
                  {isShowRelateStore && !pinError && location?.length > 0 && (
                    <div className={styles.relatestore}>
                      <b>Nearest Woodenstreet Stores</b>
                      <ul className={styles.storeBox}>
                        {showView?.show && (
                          <li className={styles.storesort} data-sort="100">
                            <CustomLink
                              target="_blank"
                              href={showView.href}
                              className={styles.view}
                            >
                              View All
                            </CustomLink>
                          </li>
                        )}
                        {location?.map(
                          (place: any, i: number) =>
                            // place?.latitude && place?.longitude &&
                            place?.mid1_banner_image?.length > 0 && (
                              <li className={styles.storesort} key={i}>
                                <CustomLink
                                  target="_blank"
                                  href={'/' + place.url}
                                >
                                  <WsStaticImage
                                    src={'/images/header/location.svg'}
                                    alt="media"
                                    width={25}
                                    height={25}
                                    //loading="eager"
                                    priority
                                  />
                                  <span>
                                    {decodeHTMLEntities(
                                      place?.display?.trim() ??
                                        place?.store_address?.trim()
                                    )}
                                  </span>
                                </CustomLink>
                              </li>
                            )
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </li>
            <li onClick={handleToggleModal}>
              <div className={styles['fast-delivery']}>
                <svg
                  width={28}
                  height={25}
                  viewBox="0 0 78 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M52.1857 8.57422V44.4989H31.5255C31.4211 42.878 30.7037 41.3575 29.5188 40.2465C28.3339 39.1355 26.7706 38.5172 25.1462 38.5172C23.5219 38.5172 21.9586 39.1355 20.7737 40.2465C19.5888 41.3575 18.8713 42.878 18.767 44.4989H14.7236V8.57422H52.1857Z"
                    stroke="#E27A34"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M73.4781 31.8199V44.4992H69.2253C69.1209 42.8782 68.4034 41.3579 67.2186 40.2468C66.0337 39.1357 64.4702 38.5174 62.846 38.5174C61.2217 38.5174 59.6584 39.1357 58.4735 40.2468C57.2886 41.3579 56.571 42.8782 56.4667 44.4992H52.1875V19.1406H61.0894L73.4781 31.8199Z"
                    stroke="#E27A34"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M73.4781 31.8203V44.4996H69.2253C69.1209 42.8786 68.4034 41.3582 67.2186 40.2472C66.0337 39.1361 64.4702 38.5178 62.846 38.5178C61.2217 38.5178 59.6584 39.1361 58.4735 40.2472C57.2886 41.3582 56.571 42.8786 56.4667 44.4996H52.1875V31.8203H73.4781Z"
                    stroke="#E27A34"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M25.1338 50.8394C28.6642 50.8394 31.5262 48.001 31.5262 44.4998C31.5262 40.9985 28.6642 38.1602 25.1338 38.1602C21.6033 38.1602 18.7412 40.9985 18.7412 44.4998C18.7412 48.001 21.6033 50.8394 25.1338 50.8394Z"
                    stroke="#E27A34"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M62.8339 50.8394C66.3643 50.8394 69.2263 48.001 69.2263 44.4998C69.2263 40.9985 66.3643 38.1602 62.8339 38.1602C59.3034 38.1602 56.4414 40.9985 56.4414 44.4998C56.4414 48.001 59.3034 50.8394 62.8339 50.8394Z"
                    stroke="#E27A34"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.28711 12.8008H9.56853"
                    stroke="#E27A34"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.29004 21.2539H9.57145"
                    stroke="#E27A34"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.29004 29.7051H9.57145"
                    stroke="#E27A34"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Fast Delivery {address}
              </div>
            </li>
          </ul>

          <RightLinks />
        </div>
      </div>
      {isModalOpen && (
        <FreeDelivery
          open={isModalOpen}
          handleModal={handleToggleModal}
          setPin={setLocations}
        />
      )}
    </div>
  );
};

export default DiscountStrip;
