import WsStaticImage from '@/components/common/WsStaticImage';
import CustomLink from '@/components/CustomLink';
import { RootState } from '@/store';
import dynamic from 'next/dynamic';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from './style.module.scss';
const HeaderInner = dynamic(() => import('./HeaderInner'), { ssr: false });
const MenuMain = dynamic(() => import('./MenuMain'), { ssr: false });
const Footer = dynamic(() => import('./Footer'));

interface Props {
  // Define the props for your component here
}
const HeaderNav: React.FC<Props> = (
  {
    /* Destructure props here */
  }
) => {
  const { visible } = useSelector((state: RootState) => state.mobileMenu);

  useEffect(() => {
    if (visible) {
      !document.body.className.includes('overflow-hidden')
        ? (document.body.className += ' overflow-hidden')
        : '';
    } else {
      document.body.className = document.body.className.replace(
        ' overflow-hidden',
        ''
      );
    }
  }, [visible]);

  return (
    <nav
      className={`${styles['menu-header']} sidebar ${visible ? 'sidebar-add' : ''}`}
    >
      <HeaderInner />

      <CustomLink
        href={'/offline-furniture-store'}
        className={styles['store-menu']}
      >
        <WsStaticImage
          src={'/images/header/store.png'}
          alt="store"
          width={50}
          height={24}
          quality={100}
        />
        <div>
          <p>Get Extra Upto 10% OFF</p>
          <span className={styles['menu-link']}>
            {' '}
            On Visiting Your Nearest Store
          </span>
        </div>
      </CustomLink>

      <div className={styles['menu-wrapper']}>
        <MenuMain />
      </div>

      <Footer />
    </nav>
  );
};

export default HeaderNav;
