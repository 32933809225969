import React, { useRef, useState } from 'react';
import Image, { ImageProps } from 'next/image';
import SkeletonLoader from './SkeletonLoader';

interface WsImageProps extends ImageProps {
  alt: string;
  customerLoader?: boolean;
  isProduct?: boolean;
  classNameCls?: any;
}

const WsImage: React.FC<WsImageProps> = ({
  src,
  alt,
  customerLoader = true,
  isProduct = false,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);
  const s3path = process.env.NEXT_PUBLIC_PRODUCT_IMAGE_S3_BUCKET_BASE_URL;

  const [fullPath, setFullPath] = useState(
    typeof src === 'string' && src.endsWith('.svg') ? src : `${s3path}${src}`
  );

  const handleLoad = () => {
    setLoading(false);
  };

  const handleError = () => {
    console.error(`Failed to load image: ${fullPath}`);
    if (isProduct) {
      const newPath = fullPath
        .replaceAll('810', '880')
        .replaceAll('702', '518');
      setFullPath(newPath);
    } else {
      setFullPath('/images/error.jpg');
    }
  };
  return (
    <>
      {loading && customerLoader && <SkeletonLoader />}
      <Image
        {...props}
        src={`${fullPath}`}
        alt={alt}
        ref={imgRef}
        onLoad={handleLoad}
        onError={handleError}
      />
    </>
  );
};

export default WsImage;
