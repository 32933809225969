import React, { useEffect, useState } from 'react';
import Image, { ImageProps } from 'next/image';

interface WsStaticImageProps extends ImageProps {
  alt: string;
  classNameCls?: string;
  className?: string;
}

const WsStaticImage: React.FC<WsStaticImageProps> = ({
  src,
  alt,
  classNameCls,
  className,
  ...props
}) => {
  const s3path = process.env.NEXT_PUBLIC_IMAGE_S3_BUCKET_BASE_URL;
  const [fullPath, setFullPath] = useState(
    typeof src === 'string' && src.endsWith('.svg') ? src : `${s3path}${src}`
  );
  const handleError = () => {
    console.error(`Failed to load image: ${fullPath}`);
    setFullPath('/images/error.jpg');
  };
  const handleLazyLoad = () => {
    const images = document.querySelectorAll<HTMLImageElement>('.lazy-loading');

    images.forEach((img) => {
      if (img.dataset.src) {
        img.src = img.dataset.src; // Set src attribute
        img.removeAttribute('data-src'); // Remove data-src
        img.classList.remove('lazy-loading'); // Remove lazy-loading class
      }
    });

    // Remove event listeners after loading images
    document.removeEventListener('mouseover', handleLazyLoad);
    document.removeEventListener('click', handleLazyLoad);
    document.removeEventListener('touchstart', handleLazyLoad);
  };
  useEffect(() => {
    // Add event listener to the whole document
    document.addEventListener('mouseover', handleLazyLoad);
    document.addEventListener('click', handleLazyLoad);
    document.addEventListener('touchstart', handleLazyLoad);

    return () => {
      document.removeEventListener('mouseover', handleLazyLoad);
      document.removeEventListener('click', handleLazyLoad);
      document.removeEventListener('touchstart', handleLazyLoad);
    };
  }, []);

  return <Image src={fullPath} alt={alt} onError={handleError} {...props} />;
  return classNameCls != 'lazy-loading' ? (
    <Image src={fullPath} alt={alt} onError={handleError} {...props} />
  ) : (
    <img
      data-src={fullPath}
      alt={alt}
      className={`${classNameCls} ${className}`}
      {...props}
    />
  );
};

export default WsStaticImage;
// export default memo(WsStaticImage);
